import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import "./NewsBanner.scss"
import moment from "moment";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import PlayVideo from "../PlayVideo/PlayVideo";
// Header component

const NewsBanner = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [shareIcons, setShareicons] = React.useState(false);

    const shareurl = typeof window !== "undefined" ? window.location.href : "";

    const openShareicons = () => {
        setShareicons(true)
        if (shareIcons === true) {
            setShareicons(false)
        }
    }

    const loopList = ['Home 1', 'Home 2', 'Home 3', 'Home 4', 'Home 5'];

    const trackerShare = event => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "Share - social icons",
            formType: event + " - " + shareurl,
            formId: "Share",
            formName: "Share",
            formLabel: "Share",
        })
    }

    const ImageRenderList = ({item, imagename}) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.Banner_Image_Transforms) {
            processedImages = item?.imagetransforms?.Banner_Image_Transforms;
        }
        return (
            <ImageTransform
            imagesources={item?.Banner_Image?.url}
            renderer="pic-src" imagename={imagename}
            attr={{ alt: item?.Banner_Image?.alternativeText ? item?.Banner_Image?.alternativeText : item?.Title, className: 'm-0' }}
            imagetransformresult={processedImages}
            id={item?._id}
        />
        )
    }

    return (
        <React.Fragment>
            <section className="news-banner">
                <Container>
                    <Row>
                        <Col md={12} className="order-md-2" >
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="news-img">
                                    {/* <picture>
                                        <source media="(min-width:992px)" srcSet={Img03} alt="img" />
                                        <source media="(min-width:768px)" srcSet={Img02} alt="img" />
                                        <img src={Img01} alt="banner" />
                                    </picture> */}
                                    {props?.data?.Banner_Image?.url &&
                                    <ImageRenderList item={props?.data} imagename={"news-and-videos.Banner_Image.banner"} />
                                    }
                                    {props?.data?.Video_Url && <i className="video-icon" onClick={() => { setPlay(true) }}></i>}
                                    {(props?.data?.Video_Url && isPlay) && <div className="fullscreen-video">
                                        {isPlay &&
                                            <React.Fragment>
                                                <div className="d-block">
                                                    <PlayVideo
                                                        isOpen={isPlay}
                                                        videoId={props?.data?.Video_Url}
                                                        stopPlay={setPlay}
                                                        width={'100%'} height={'100%'}
                                                        fullscreen={true}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>}
                                </div>
                            </ScrollAnimation>
                        </Col>
                        <Col md={12} >
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="news-details">
                                    <Row>                                       
                                        <Col sm={12} className="details-news-wrapper">
                                            <div className="news-info">
                                                <h1>{props?.data?.Title}</h1>
                                            </div>
                                            {props?.data.Url === "vipmembers" ?
                                                <div className="sm-text mb-3">
                                                    <span className="text vipmembers-text">
                                                        Take advantage of our launch offer! Sign up now and receive the whole of 2023 at no cost - a subscription saving of £60!*
                                                        <br/><br/>
                                                        <span className="smtext">
                                                        *We will be in touch towards the end of this year to sign you up for our subscription of £4.99/month, to continue using our amazing discounts and local offers in 2024.</span>
                                                        <br/><br/>
                                                        Click <a href="/about/tlc-vip/disclaimer/"><u>here</u></a> for our tlc VIP disclaimer
                                                        </span>
                                                </div>
                                                :
                                                <div className="sm-text mb-3">
                                                    {props?.data?.Date && <><span className="text"> <span className="date-marker">{moment(props?.data?.Date).format("MMMM Do YYYY")}</span> </span> <span className="breadcrumb-divider"> /</span></>}

                                                    {props?.data?.Category && <>
                                                        <span className="market">
                                                            <Link to={`${props?.breadcrumbs?.url}?type=${props?.data?.Category?.Title.toLowerCase()}`}>
                                                                {props?.data?.Category?.Title}
                                                            </Link>
                                                        </span> /
                                                    </>
                                                    }

                                                    <a href="javascript:;" className="icons position-relative">
                                                        <i className="icon-share cursor-pointer" onClick={openShareicons} />
                                                        <span className="text cursor-pointer" onClick={openShareicons}> Share <span className="d-none d-md-inline-block">this Article</span></span>
                                                        <div className="position-absolute" style={{ top: 25 }}>
                                                            {shareIcons && (
                                                                <div onClick={openShareicons} className="d-flex flex-row property-share-social-icons2 mobile-details-socila-share ps-3">
                                                                    <FacebookShareButton
                                                                        onClick={() =>
                                                                            trackerShare("FacebookShareButton")
                                                                        }
                                                                        url={shareurl}
                                                                        className="my-share-button facebook-share mx-1"
                                                                    >
                                                                        <FacebookIcon
                                                                            size={32}
                                                                            round={false}
                                                                            borderRadius={`10`}
                                                                        />
                                                                    </FacebookShareButton>
                                                                    <TwitterShareButton
                                                                        onClick={() =>
                                                                            trackerShare("TwitterShareButton")
                                                                        }
                                                                        url={shareurl}
                                                                        className="my-share-button twitter-share mx-1"
                                                                    >
                                                                        <TwitterIcon
                                                                            size={32}
                                                                            round={false}
                                                                            borderRadius={`10`}
                                                                        />
                                                                    </TwitterShareButton>
                                                                    <LinkedinShareButton
                                                                        onClick={() =>
                                                                            trackerShare("LinkedinShareButton")
                                                                        }
                                                                        url={shareurl}
                                                                        className="my-share-button linkedin-share mx-1"
                                                                    >
                                                                        <LinkedinIcon
                                                                            size={32}
                                                                            round={false}
                                                                            borderRadius={`10`}
                                                                        />
                                                                    </LinkedinShareButton>
                                                                    <WhatsappShareButton
                                                                        onClick={() =>
                                                                            trackerShare("WhatsappShareButton")
                                                                        }
                                                                        url={shareurl}
                                                                        className="my-share-button whatsapp-share mx-1"
                                                                    >
                                                                        <WhatsappIcon
                                                                            size={32}
                                                                            round={false}
                                                                            borderRadius={`10`}
                                                                        />
                                                                    </WhatsappShareButton>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </a>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
                {/* <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId={props?.data?.Video_Url} /> */}
            </section>
        </React.Fragment>
    )
}
export default NewsBanner;
